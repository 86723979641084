import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faLinkedin,
  faGithub,
  faBehance,
} from "@fortawesome/free-brands-svg-icons"
import Fade from "react-reveal/Fade"

import contactStyles from "../contact/contact.module.scss"

const Contact = () => {
  return (
    <section id="contact" className={contactStyles.contact}>
      <div className={contactStyles.contact__container}>
        <Fade>
          <h2 className={contactStyles.contact__title}>Get In Touch</h2>
          <div className={contactStyles.contact__main}>
            <form
              name="contact-form"
              method="post"
              onSubmit="submit"
              netlify
              className={contactStyles.form}
            >
              <input type="hidden" name="form-name" value="contact-form" />
              <div className={contactStyles.form__nameContainer}>
                <label
                  className={contactStyles.form__nameLabel}
                  type="text"
                  name="name2"
                >
                  Name:
                  <input
                    className={contactStyles.form__nameInput}
                    name="name"
                    type="text"
                  />
                </label>
                <br></br>
              </div>

              <div className={contactStyles.form__emailContainer}>
                <label
                  className={contactStyles.form__emailLabel}
                  type="email"
                  name="email2"
                >
                  Email:
                  <input
                    className={contactStyles.form__emailInput}
                    name="email"
                    type="text"
                  />
                </label>
                <br></br>
              </div>

              <div className={contactStyles.form__messageContainer}>
                <label
                  className={contactStyles.form__messageLabel}
                  type="text"
                  name="message"
                >
                  Your message:
                  <textarea
                    className={contactStyles.form__messageArea}
                    name="text"
                  ></textarea>
                </label>
                <br></br>
              </div>

              <button className={contactStyles.form__btn} type="submit">
                Send Message
              </button>
            </form>

            <div className={contactStyles.socials__container}>
              <h2 className={contactStyles.socials__title}>Follow me</h2>
              <Link
                className={contactStyles.socials__item}
                to="#"
              >
                <FontAwesomeIcon
                  className={contactStyles.socials__icon}
                  icon={faGithub}
                />
              </Link>

              <Link
                className={contactStyles.socials__item}
                to="#"
              >
                <FontAwesomeIcon
                  className={contactStyles.socials__icon}
                  icon={faLinkedin}
                />
              </Link>

              <Link
                className={contactStyles.socials__item}
                to="#"
              >
                <FontAwesomeIcon
                  className={contactStyles.socials__icon}
                  icon={faTwitter}
                />
              </Link>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  )
}

export default Contact
